@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap");

body {
  font-family: "Roboto Condensed" sans-serif !important;
}
:root {
  --primary-white: #ffffff;
  --primary-light-grey: #f4f4f4;
  --primary-grey: #ccd1d9;
  --primary-dark-gray: #666666;
  --primary-dark: rgba(52, 58, 64);
  --primary-blue: #3ca6b1;
  --primary-pink: #e53278;
  --p-light-blue: #74d8e3;
  --p-darck-blue: #007782;
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
  --primary-light-grey: #f4f4f4;
  --primary-black: #000000;
  --primary-transparent-black: rgba(0, 0, 0, 0.7);
}

/******************navbar**********/
nav {
  z-index: 2;
}
.logo {
  width: 6.25rem;
}
/********menu links**********/
nav a.nav-link {
  font-size: 0.8rem;
  letter-spacing: 0.35rem;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}
.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: var(--primary-white) !important;
  border-top: 0.1875rem solid var(--primary-dark);
}
.navbar-brand {
  max-width: 17.25rem;
  padding: 0 !important;
  height: 6rem !important;
  color: var(--primary-white) !important;
}
@media (max-width: 768px) {
  .navbar-brand {
    width: 7rem;
  }
}

.navbar-nav > li > a.active,
.navbar-nav > li > a:focus {
  border-top: 0.1875rem solid var(--primary-pink);
}
.navbar-nav > li > a:hover {
  border-top: 0.1875rem solid var(--primary-pink);
}
/***************header **/
.header-wraper {
  position: relative;
  background: url(mujerplaya.png);
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  z-index: 1;
}

.main-info h1 {
  color: var(--primary-pink);
  text-transform: uppercase;
}

/************typed text*/
.typed-text {
  font-size: 4rem;
  color: var(--primary-white);
}

@media (max-width: 768px) {
  .main-info h1 {
    font-size: 1.5rem !important;
  }

  .typed-text {
    font-size: 1.3rem !important;
  }
}

/********header main offer button**************/
.btn-main-offer {
  border: 1px solid var(--primary-blue);
  text-transform: uppercase;
  border-radius: 2px solid var(--p-light-blue);
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: var(--primary-black);
  margin: 2rem;
}

.btn-main-offer:hover {
  text-decoration: none;
  background-color: var(--primary-blue);
  color: var(--primary-pink);
  transition: 1s ease-in-out;
}

/************PARTICLES JS *********/
.tsparticles-canvas-el {
  position: absolute !important;
  height: 100vh;
  width: 100vh;
  z-index: 1 !important;
}

@media (max-width: 768px) {
  .particles-canvas {
    display: yes;
  }
}
/************about me *********/
.photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-img {
  width: 20rem;
  border-radius: 50%;
  padding: 0.3rem;
  border: 2px solid var(--p-light-blue);
}

.about-heading {
  color: var(--p-darck-blue);
  text-transform: uppercase;
}

@media (max-width: 468px) {
  .profile-img {
    width: 10rem;
  }
}
/************services *********/
.services {
  text-align: center;
  padding-bottom: 2rem;
  background: var(--primary-light-grey);
}

.services h1 {
  color: var(--p-darck-blue);
  text-transform: uppercase;
}
.services .circle {
  position: relative;
  margin: 0.375rem auto;
  background: var(--primary-pink);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.services .icon {
  font-size: 1.4rem;
  color: var(--primary-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.services .box {
  height: 16rem;
  border-bottom: 0.3125rem solid var(--primary-pink);
  padding: 00.625rem;
  margin-bottom: 1.875rem;
  background: var(--primary-white);
  transition: 0.3s ease-in-out;
}
.services .box:hover {
  background: var(--primary-pink);
  border-bottom: 0.3125rem solid var (--primary-dark);
}
.services .box:hover .icon {
  color: var(--primary-dark);
}
.services .box:hover .circle {
  color: var(--primary-white);
}

.services .box:hover p {
  color: var(--primary-white);
}
/************experience *********/
.experience {
  padding: 0.7rem;
}
.experience-wrapper {
  position: relative;
  padding: 3.125rem 0;
  overflow: hidden;
}
.experience h1 {
  text-transform: uppercase;
  color: var(--primary-pink);
}
.experience-wrapper::before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  margin-left: -0.0625rem;
  width: 0.125rem;
  height: 100%;
  background: var(--primary-grey);
  z-index: 1;
}
.timeline-block {
  width: calc(50% + 0.5rem);
  display: flex;
  justify-content: space-between;
  clear: both;
}
.timeline-block-right {
  float: right;
}
.timeline-block-left {
  float: left;
  direction: rtl;
}

.marker {
  padding: 1.5rem;

  margin-top: 0.625rem;
  z-index: 3;
}
.timeline-content {
  width: 95%;
  padding: 0 0.9375rem;
  color: var(--primary-grey);
  direction: ltr;
}

.timeline-content {
  width: 95%;
  padding: 0 0.9375rem;
  color: var(--primary-grey);
  direction: ltr;
}
.timeline-content h3 {
  margin-top: 0.3125rem;
  color: var(--primary-red);
  font-weight: 900;
}

/*-------------MEDIA---------*/
@media (max-width: 768px) {
  .experience .container {
    padding-left: 0;
  }

  .experience-wrapper::before {
    left: 0.5rem;
    margin-bottom: 1.875rem;
  }
  .timeline-block-right {
    float: none;
    width: 100%;
  }
  .timeline-block-left {
    float: none;
    direction: ltr;
    width: 100%;
  }
}
/*-------------PORTFOLIO---------*/
.portfolio-wrapper {
  background: var(--p-light-blue);
  padding: 3rem 0;
}
.portfolio-wrapper h1 {
  color: var(--primary-pink);
}
.portfolio-image {
  width: 15rem;
  border: 1px solid var(--primary-pink);
  padding: 0 0rem;
}
.portfolio-image-box {
  position: relative;
  margin: 1rem;
}
/*-------------MEDIA---------*/
.overflow {
  position: absolute;
  opacity: 0;
  background: var(--p-darck-blue);
  width: 15rem;
  height: 8rem;
  top: 0;
  border: 1px solid var(--primary-pink);
  cursor: pointer;
}
.portfolio-image-box:hover .overflow {
  opacity: 10%;
  transition: 0.3s ease-in-out;
}
.portfolio-icon {
  position: absolute;
  opacity: 0;
  margin-left: 0.5rem;
  margin-top: 1rem;
  color: var(--primary-pink);
  font-size: 1.5rem;
}

.portfolio-image-box:hover .portfolio-icon {
  opacity: 70%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.popupbox-wrapper {
  margin-top: 10rem;
  z-index: 1;
}
/*-------------overflow box---------*/
.portfolio-image-popupbox {
  width: 39rem;
  padding: 0 0.5rem;
}

.hyper-link {
  cursor: pointer;
  color: var(--primary-pink);
}
/*-------------MEDIA---------*/
@media (max-width: 768px) {
  .portfolio-image-popupbox {
    width: 95%;
  }
}
@media (max-height: 570px) {
  .popupbox-wrapper {
    height: 90%;
  }
  .portfolio-image-popupbox {
    width: 90%;
  }
}
@media (max-height: 280px) {
  .popupbox-wrapper {
    height: 90%;
  }
  .portfolio-image-popupbox {
    width: 50%;
  }
}
/***************Testimonials ************/

.testimonials {
  /* background: url(testimonials-bg.png); */
  background-size: cover;
  background-color: var(--primary-dark-gray);
  text-align: center;
  padding-bottom: 4rem;
  margin-bottom: 0;
  width: 100;
}
.testimonials h1 {
  color: var(--primary-pink) !important;
  padding: 3rem 0;
  text-transform: uppercase;
}

.testimonials-content {
  text-align: center;
  max-width: 53.125rem;
  margin: 0 auto 2rem auto;
  background: var(--primary-transparent-black);
}
.myCarousel {
  color: var(--primary-transparent-black);
  background: transparent;
  margin-top: -6%;
  width: 100%;
  margin-left: auto;
  margin-left: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  height: 17.875rem;
}

.myCarousel h3 {
  color: var(--primary-grey);
  font-weight: 600;
  text-transform: uppercase;
}

.myCarousel p {
  color: var(--primary-white);
}
.carousel .slide img {
  width: 10rem !important;
  border: 50%;
}
.carousel .slide {
  background: transparent !important;
  height: 25% !important;
  padding-top: 2rem;
}
.carousel-root {
  margin: auto !important;
  margin-top: 3% !important;
  background: url(testimonials-bg.png);
}
/***************Contacts Component ************/
.contacts {
  background: var(--primary-dark);
  padding: 3rem 0;
}
.contacts h1 {
  color: var(--primary-pink);
  padding: 1.3 rem 0;
  text-transform: uppercase;
}
.contacts p {
  color: var(--p-light-blue);
  font-size: 1.2rem;
}
/***************Inputs ************/
.contacts input,
.contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid var(--primary-dark-gray);
  border-radius: 0;
  color: var(--primary-grey);
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
  padding-left: 0;
}
.contacts .container {
  padding: 4rem 0;
}
.contacts input:focus,
.contacts textarea:focus {
  background: transparent;
  color: var(--primary-pink);
  border-bottom: 0.125rem solid var(--primary-dark-gray);
}

.contacts textarea {
  height: 12.2rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
}

.line {
  position: relative;
  top: -0.8rem;
  width: 0;
  height: 00.125rem;
  background: var(--primary-pink);
  display: inline-block;
}

input:focus + .line,
textarea:focus + .line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}
input.form-control {
  margin: -0.3rem 0;
}
textarea.form-control {
  margin: -0.3rem 0;
}

/***************Media ************/
@media (max-width: 768px) {
  .contacts,
  .container {
    padding: 0 1rem;
  }
}
.contacts p {
  padding: 0 0.4rem;
}
/***************Footer Component ************/
.footer {
  background: var(--primary-black);
  color: var(--primary-dark-gray);
  padding: 3rem;
}

.footer a {
  text-decoration: none;
  color: var(--primary-dark-gray);
}
.footer a:hover {
  text-decoration: none;
  color: var(--primary-pink);
}
.footer p {
  margin-bottom: 0;
}
/***************api nav bar Component ************/
.navbar-poke {
  margin: 0;
  height: 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.navbar-image {
  width: 160px;
}

.searchbar-container {
  display: flex;
  margin: 0px auto;
  padding: 0 20px;
  align-items: center;
}
.searchbar {
  background: #e53278;
  margin-right: 20px;
}
.searchbar input {
  padding: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  border: none;
  height: 54px;
}

.searchbar input:focus {
  outline: none;
}
.searchbar-btn button {
  background: chartreuse;
  border: none;
  border-radius: 10px;
  height: 100%;
  color: chocolate;
  height: 44px;
}

.headerpoke {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.pokedex-grid {
  display: grid;
  grid-auto-columns: repeat (3, 1fr);
  grid-gap: 10px;
}
